export const formatDate = (value: string): string => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE");
};

export const formatDateTime = (value: string): string => {
  const date = new Date(value);
  return date.toLocaleString("de-DE");
};

export const renderDateRange = (member: {
  startDate?: string;
  endDate?: string;
}) => {
  if (!member.startDate && !member.endDate) {
    return ''
  }

  if (member.endDate) {
    return `${formatDate(member.startDate)} - ${formatDate(member.endDate)}`;
  }

  return formatDate(member.startDate);
};
