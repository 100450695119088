import React, { useState } from "react";
import { ColumnConfig } from "../../../common/types/typing";
import Layout from "../../../components/layout";
import TableBody from "../../../components/UI-Elements/Table/Body";
import TableHeader from "../../../components/UI-Elements/Table/Header";
import TableRow from "../../../components/UI-Elements/Table/Row";
import Table from "../../../components/UI-Elements/Table/Table";
import Link from "../../../components/UI-Elements/Navigation/Link";
import { graphql, useStaticQuery } from "gatsby";
import { SpCoin } from "../../../common/types/supabase";
import Title from "@components/UI-Elements/Typography/Title";
import { formatDateTime } from "@common/helpers/date-helper";
import SEO from "@components/seo";

const config: ColumnConfig<SpCoin>[] = [
  {
    key: "logo",
    title: "Logo",
    render: (data) => data?.image?.thumb ? <img src={data?.image?.thumb} alt={data?.name} className="w-8" /> : '',
  },
  {
    key: "name",
    title: "name",
    render: (data) => data.name,
  },
  {
    key: "coingecko_id",
    title: "coingecko_id",
    render: (data) => data.coingecko_id,
  },
  {
    key: "created_at",
    title: "created_at",
    render: (data) => formatDateTime(data.created_at || ''),
  },
  {
    key: "open",
    title: "open",
    render: (data) => (
      <Link href={`/coin/${data.coingecko_id}`} type="button">
        Öffnen
      </Link>
    ),
  },
];

const Page = () => {
  const [q, setQ] = useState("");
  const data = useStaticQuery(
    graphql`
      query {
        allSupabaseCoins {
          nodes {
            name
            coingecko_id
            created_at
            image {
              thumb
              small
              large
            }
          }
        }
      }
    `
  );
  const CoinRow: React.FC<{ coin: SpCoin; columnConfig: ColumnConfig<SpCoin>[] }> = ({ coin, columnConfig }) => {
    const columns = columnConfig.map((column) => column.render(coin));
    return <TableRow columns={columns} />;
  };
  const coinList: SpCoin[] = data.allSupabaseCoins.nodes;
  const filtered = q ? coinList.filter(({ name }) => (name || '').toLowerCase().includes(q.toLowerCase())) : coinList;

  return (
    <Layout contentWidth="full">
      <SEO title="Coins in Supabase" robots="noindex" />
      <Title>Coins in Supabase</Title>
      <div className="my-6">
        <label htmlFor="search" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          Suche
        </label>
        <input
          type="text"
          id="search"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Bitcoin, Ethereum, ..."
          value={q}
          onChange={(e) => setQ(e.target.value)}
          required
        />
      </div>
      <section className="py-4">
        <Table>
          <TableHeader columns={config} />
          <TableBody>
            {filtered.slice(0, 100).map((coin, index) => (
              <CoinRow key={index} coin={coin} columnConfig={config} />
            ))}
          </TableBody>
        </Table>
      </section>
    </Layout>
  );
};

export default Page;
